<template>
  <section
    :class="[
      {
        'course__detail--section': !isAuthorized,
        'not-found': getPageNotFound
      }
    ]"
  >
    <div class="course__bg--wrapper">
      <div
        :class="{
          'container cd__wrapper fixed-container': !isAuthorized,
          'h-100': isAuthorized
        }"
      >
        <div
          :class="['cd__course-detail', { 'cd-top-section': !isAuthorized }]"
        >
          <div
            :class="['lx-breadcrumb', { 'd-none d-sm-block': isAuthorized }]"
          >
            <LxpBreadcrumb
              :items="setBreadcrumbsPath"
              @click="navigateToLink"
            />
          </div>
          <div class="cd-main__wrapper">
            <div
              class="cd-main__container"
              v-if="!isLoading && Object.keys(getSelectedCourse).length != 0"
            >
              <b-row class="m-0">
                <b-col cols="12" sm="12" md="12" lg="9" class="p-0">
                  <div class="cd__title--style" v-b-visible="visibleHandler">
                    {{ getSelectedCourse.title }}
                  </div>
                </b-col>
                <b-col
                  lg="3"
                  class="text-right cd-three-dots-menu--style d-none d-sm-block"
                  v-if="cStep === 2 && !isCourseEnded && isAuthorized"
                >
                  <!-- TODO: TO be replaced with didactica -->
                  <b-dropdown
                    right
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template #button-content>
                      <img
                        src="@/assets/images/vle/threedots.svg"
                        alt="threedots"
                      />
                    </template>

                    <b-dropdown-item class="unenroll" v-b-modal.unenroll-modal>
                      <div class="ac-mc__list">
                        <img
                          src="@/assets/images/icons/unenroll-module.svg"
                          alt="unenroll"
                          class="ac-mc__list-img"
                        />
                        {{ $t("course.unenroll.title") }}
                      </div>
                    </b-dropdown-item>
                  </b-dropdown>
                </b-col>
              </b-row>
              <div
                v-if="isAuthorized"
                class="selected-course-sections-hours d-none d-sm-flex"
              >
                <img
                  src="@/assets/images/courseware/modules.svg"
                  alt="modules"
                />
                <span class="cd__modules--style">
                  {{
                    getSelectedCourse.sections.length > 1
                      ? getSelectedCourse.sections.length
                      : ""
                  }}
                  {{
                    getSelectedCourse.sections.length > 1
                      ? $t("search.tab.modules")
                      : $t("course.x_modules_one")
                  }}
                </span>
                <img
                  v-if="getSelectedCourse.effort_time"
                  src="@/assets/images/courseware/time.svg"
                  alt="effort"
                />
                <span
                  v-if="getSelectedCourse.effort_time"
                  class="cd__effort--style"
                  :title="getSelectedCourse.effort_time"
                >
                  {{
                    $t("course.x_modules_hours", {
                      s: getSelectedCourse.effort_time
                    })
                  }}
                </span>
              </div>
              <ul class="course-info" @click="showCourseDetailModal" v-else>
                <li class="d-lg-none">
                  <img
                    src="@/assets/images/landing/course/language.svg"
                    alt="language"
                  />
                  <span :title="language" class="d-none d-md-block">
                    {{ $t(`data.language.${language}`) }}
                  </span>
                  <span
                    :title="language"
                    class="d-block d-md-none text-uppercase"
                  >
                    {{ language }}
                  </span>
                </li>
                <li>
                  <img
                    src="@/assets/images/landing/course/modules.svg"
                    alt="modules"
                  />
                  <span :title="allSequences.length">
                    {{ getSelectedCourse.sections.length }}
                    <span class="d-none d-md-block">
                      &nbsp;{{ $t("search.tab.modules") }}
                    </span>
                  </span>
                </li>
                <li v-if="getSelectedCourse.hours_of_effort">
                  <img
                    src="@/assets/images/landing/course/time.svg"
                    alt="time"
                  />
                  <span
                    :title="getSelectedCourse.hours_of_effort"
                    class="d-none d-md-block"
                  >
                    {{
                      $t("course.x_modules_hours", {
                        s: getSelectedCourse.hours_of_effort
                      })
                    }}
                  </span>
                  <span
                    :title="getSelectedCourse.hours_of_effort"
                    class="d-block d-md-none"
                  >
                    {{
                      $t("course.x_modules_hours_mobile", {
                        s: getSelectedCourse.hours_of_effort
                      })
                    }}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      :class="{
        'container cd__wrapper fixed-container': !isAuthorized,
        'h-100': isAuthorized
      }"
      :id="isAuthorized ? 'qa-course-detail-view' : ''"
    >
      <div
        :class="['cd__course-detail', { 'cd-bottom-section': !isAuthorized }]"
      >
        <div class="cd-main__wrapper">
          <Shimmer v-if="isLoading"></Shimmer>
          <div
            class="cd-main__container"
            v-else-if="!isLoading && Object.keys(getSelectedCourse).length != 0"
          >
            <div
              :class="{
                'd-flex': isAuthorized,
                'cd-video-section': !isAuthorized
              }"
            >
              <div
                :class="{
                  'cd-main_course-content': isAuthorized,
                  row: !isAuthorized
                }"
              >
                <div
                  :class="{
                    'mb-3 col-md-12 col-lg-9 left-panel': !isAuthorized
                  }"
                >
                  <div v-if="isAuthorized" class="cd__prg-overview__banner">
                    <b-img
                      fluid-grow
                      center
                      class="cd__overview--style"
                      :src="placeholderImage || getSelectedCourse.image_url"
                      :alt="getSelectedCourse.title"
                      @error="handleError"
                    ></b-img>
                    <div
                      class="intro_video_url_sm d-block d-lg-none"
                      v-if="getSelectedCourse.intro_video_url"
                      :style="{
                        'background-image':
                          'url(' + getSelectedCourse.image_url + ')'
                      }"
                    >
                      <img
                        v-if="getSelectedCourse.intro_video_url"
                        src="@/assets/images/icons/play-blue-title.svg"
                        alt="play"
                        width="56px"
                        height="56px"
                        class="c-pointer"
                        @click="$bvModal.show('intro-video-modal')"
                      />
                    </div>
                  </div>

                  <div v-else class="cd__prg-overview__banner">
                    <div
                      class="video-overlay"
                      v-if="
                        getSelectedCourse.intro_video_url && isPlayIconVisible
                      "
                    >
                      <img
                        src="@/assets/images/landing/course/play.svg"
                        alt="play"
                        width="56px"
                        height="56px"
                        class="play-icon c-pointer"
                        @click="hidePlayIcon(false)"
                      />
                      <img
                        :src="youtubeThumbnail"
                        alt="video thumbnail"
                        class="cd__overview--style"
                      />
                    </div>
                    <iframe
                      class="course-video"
                      frameBorder="0"
                      :src="getSelectedCourse.intro_video_url"
                      title="Course Enroll"
                      v-if="getSelectedCourse.intro_video_url"
                    ></iframe>
                    <img
                      :src="placeholderImage || getSelectedCourse.image_url"
                      @error="handleError"
                      alt="empty"
                      width="auto"
                      height="auto"
                      class="cd__overview--style"
                      v-else
                    />
                  </div>

                  <div v-if="isAuthorized">
                    <b-row
                      class="selected-course-sections-hours d-block d-sm-none"
                    >
                      <b-col lg="12">
                        <div class="d-flex justify-content-around py-3">
                          <div>
                            <img
                              src="@/assets/images/courseware/modules-mobile.svg"
                              alt="modules"
                            />
                            <span
                              class="selected-course-sections"
                              :title="allSequences.length"
                            >
                              {{ getSelectedCourse.sections.length }}
                            </span>
                          </div>
                          <div v-if="getSelectedCourse.hours_of_effort">
                            <img
                              src="@/assets/images/courseware/time-mobile.svg"
                              alt="effort"
                            />
                            <span
                              class="selected-course-sections"
                              :title="getSelectedCourse.hours_of_effort"
                            >
                              {{ getSelectedCourse.hours_of_effort }}
                            </span>
                          </div>
                          <div>
                            <span
                              class="c-pointer"
                              @click="$bvModal.show('course-details-modal')"
                            >
                              <img
                                src="@/assets/images/layout/user-fluid-layout/language.svg"
                                alt="language"
                              />
                              <span
                                class="selected-course-modules-hours text-uppercase"
                                :title="language"
                              >
                                {{ language }}
                              </span>
                            </span>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                  <div
                    class="d-lg-none"
                    v-if="
                      legacyContent &&
                        getSelectedCourse.short_description &&
                        isAuthorized
                    "
                  >
                    <span class="cd__short-desc--resp">
                      {{ formattedBody }}
                    </span>
                    <span
                      class="cd__short-desc--more"
                      @click="showingFullText = !showingFullText"
                    >
                      See {{ showingFullText ? "less" : "more" }}
                    </span>
                  </div>
                  <div
                    class="d-flex justify-content-between self-paced-calender-icons border-bottom d-lg-none"
                  >
                    <div class="text-center">
                      <div>
                        <img
                          src="@/assets/images/courseware/self-paced-blue.svg"
                          alt="self-paced"
                        />
                      </div>
                      <div class="title">
                        {{ $t(`data.pacing.${getSelectedCourse.pacing}`) }}
                      </div>
                      <div class="description">
                        {{ $t("course_detail.hundred_percent_online") }}
                      </div>
                    </div>
                    <div class="text-center">
                      <div>
                        <img
                          src="@/assets/images/courseware/calendar-blue.svg"
                          alt="calendar"
                        />
                      </div>
                      <div class="title">
                        {{ $t("course_detail.starting") }}
                      </div>
                      <div class="description">
                        {{
                          getSelectedCourse.course_starts_at
                            | formatDate("MMM Do YYYY")
                        }}
                      </div>
                    </div>
                    <div class="text-center">
                      <div>
                        <img
                          src="@/assets/images/courseware/end-date-calendar.svg"
                          alt="calendar"
                        />
                      </div>
                      <div class="title">
                        {{ $t("course_detail.ending") }}
                      </div>
                      <div class="description">
                        {{
                          getSelectedCourse.course_ends_at
                            | formatDate("MMM Do YYYY")
                        }}
                      </div>
                    </div>
                  </div>
                  <div
                    class="cd-tabs-custom-styles"
                    :class="{ 'cd-body-section': !isAuthorized }"
                  >
                    <b-tabs pills content-class="mt-4" v-model="tabIndex" lazy>
                      <b-tab :title="$t('course.tab.course_overview')" active>
                        <div
                          class="cd-sec__des d-none d-lg-block"
                          v-if="
                            legacyContent && getSelectedCourse.short_description
                          "
                        >
                          {{ getSelectedCourse.short_description }}
                        </div>
                        <div
                          v-if="
                            legacyContent && getSelectedCourse.short_description
                          "
                        >
                          <hr
                            class=" cd__short-desc--style d-none d-lg-block"
                          />
                        </div>
                        <div class="cd-content__overview">
                          <div v-if="legacyContent">
                            <div
                              :class="{
                                'cd-overview__content': true,
                                'cd-intro': getSelectedCourse.intro_video_url
                              }"
                              v-html="getSelectedCourse.overview"
                            ></div>
                          </div>
                          <div v-else-if="overviewContent">
                            <CourseOverview
                              :key="key"
                              :isEdit="false"
                              :overviewContent="overviewContent"
                              :courseId="courseId"
                              :courseTitle="getSelectedCourse.title"
                            />
                          </div>
                        </div>
                      </b-tab>
                      <b-tab :title="$t('search.tab.modules')">
                        <div class="sp__left-modules course-details-modules">
                          <ModuleList
                            :module="m"
                            v-for="(m, i) in modules"
                            :key="i"
                            :currentModuleNo="i + 1"
                            :totalModules="modules.length"
                            :isOpen="m.isOpen"
                            :isEnable="
                              isAuthorized
                                ? getSelectedCourse.is_enrolled &&
                                  !hasFutureStartDate
                                : false
                            "
                          />
                        </div>
                      </b-tab>
                      <!-- Temporarily disable the Credentials tab for this release -->
                      <b-tab
                        v-if="
                          getSelectedCourse.credentials &&
                            getSelectedCourse.credentials.length
                        "
                        :title="$t('course.tab.credentials')"
                      >
                        <div class="sp__left-modules course-details-modules">
                          <CredentialsTabContent
                            :id="$route.params.id"
                            :isEnable="getSelectedCourse.is_enrolled"
                            :credentials="getSelectedCourse.credentials"
                          ></CredentialsTabContent>
                        </div>
                      </b-tab>
                    </b-tabs>
                  </div>
                  <div v-if="showSummary" class="cd_org_details">
                    <b-row>
                      <b-col class="cd_org_card__header_l">
                        {{ $t("course.designed_and_developed_by") }}
                      </b-col>
                      <b-col
                        v-if="spaceInfo.terms_and_conditions"
                        class="cd-org__card_header_r"
                      >
                        <div>
                          <a
                            target="blank"
                            :href="spaceInfo.terms_and_conditions"
                            >{{ $t("course_detail.terms_conditions") }}</a
                          >
                        </div>
                      </b-col>
                    </b-row>
                    <b-row class="cd_org-card">
                      <b-col class="cd_org-col" cols="12">
                        <div
                          :class="[
                            'ls-card__wrapper',
                            { 'ls-card__inactive': !showSummary }
                          ]"
                          @click="onSpaceClick(spaceInfo)"
                        >
                          <div class="ls-card__left" v-if="showSummary">
                            <img
                              :src="spaceInfo.logo"
                              alt="logo"
                              width="128"
                              height="72"
                            />
                          </div>
                          <div
                            :class="[
                              'ls-card__right',
                              { 'pl-0': !showSummary }
                            ]"
                          >
                            <h6>{{ spaceInfo.name }}</h6>
                            <p>{{ spaceInfo.short_headline }}</p>
                            <div class="ls-card__footer" v-if="showSummary">
                              <div
                                class="ls-card__footer-view"
                                @click="onSpaceClick(spaceInfo)"
                              >
                                <span>View More</span>
                                <img
                                  src="@/assets/images/module-list/arrow-right.svg"
                                  alt="edit"
                                />
                              </div>
                            </div>
                          </div></div
                      ></b-col>
                    </b-row>
                    <b-row>
                      <b-col
                        v-if="spaceInfo.terms_and_conditions"
                        class="cd_org_terms__mobile"
                        cols="12"
                      >
                        <div>
                          <a
                            target="blank"
                            :href="spaceInfo.terms_and_conditions"
                            >{{ $t("course_detail.terms_conditions") }}</a
                          >
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                  <div class="pt-2">
                    <b-row>
                      <b-col cols="12" sm="12" md="12" lg="12">
                        <div
                          class="more-learning-programs"
                          v-if="recommendedCourses.length"
                        >
                          <DashboardHeading
                            iconFileName="more-learning-programs-yellow-icon.svg"
                          >
                            {{
                              $t(
                                "course_discovery.heading.more_learning_programmes"
                              )
                            }}
                          </DashboardHeading>
                          <div class="mt-4">
                            <RecommendedCourses
                              :isLoading="isLoading"
                              :courses="recommendedCourses"
                              @click="onCard"
                            />
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </div>
                <div
                  v-if="!isAuthorized"
                  class="mb-3 col-md-3 text-right d-none d-lg-block right-panel"
                >
                  <Actions
                    :isTitleDisplay="isTitleDisplay"
                    :getSelectedCourse="getSelectedCourse"
                    :language="language"
                    :allConfig="allConfig"
                    :cStep="1"
                    :isEnrollEnabled="isEnrollEnabled('pre')"
                    :hasFutureStartDate="hasFutureStartDate"
                    :hasFutureEnrollmentDate="hasFutureEnrollmentDate"
                    :hasPassedEnrollmentDate="hasPassedEnrollmentDate"
                    :tooltipData="tooltipData"
                    :enrollSoonTooltipData="enrollSoonTooltipData"
                  />
                </div>
              </div>
              <!-- Actions component -->
              <div
                v-if="isAuthorized"
                class="cd-main_course-actions text-right d-none d-lg-block"
              >
                <Actions
                  :isTitleDisplay="isTitleDisplay"
                  :getSelectedCourse="getSelectedCourse"
                  :language="language"
                  :allConfig="allConfig"
                  :cStep="cStep"
                  :hasFutureEnrollmentDate="hasFutureEnrollmentDate"
                  :isEnrollEnabled="isEnrollEnabled()"
                  :enrollSoonTooltipData="enrollSoonTooltipData"
                  :startingSoonTooltipData="startingSoonTooltipData"
                  :reviewTooltipData="reviewTooltipData"
                  :tooltipData="tooltipData"
                  :hasPassedEnrollmentDate="hasPassedEnrollmentDate"
                  :hasFutureStartDate="hasFutureStartDate"
                  :isCourseEnded="isCourseEnded"
                  @onEnroll="onEnroll"
                />
              </div>
            </div>
          </div>
          <div
            class="d-flex align-items-center justify-content-center h-100"
            v-else
          >
            <PageNotFound
              :description="$t('error.page_not_found.subtitle')"
              :exploreButton="false"
            />
          </div>
        </div>
        <div
          v-if="isAuthorized"
          class="cd-fixed-footer-section d-block d-lg-none"
        >
          <Actions
            :isTitleDisplay="isTitleDisplay"
            :getSelectedCourse="getSelectedCourse"
            :language="language"
            :allConfig="allConfig"
            :cStep="cStep"
            :hasFutureEnrollmentDate="hasFutureEnrollmentDate"
            :isEnrollEnabled="isEnrollEnabled()"
            :enrollSoonTooltipData="enrollSoonTooltipData"
            :startingSoonTooltipData="startingSoonTooltipData"
            :reviewTooltipData="reviewTooltipData"
            :tooltipData="tooltipData"
            :hasPassedEnrollmentDate="hasPassedEnrollmentDate"
            :hasFutureStartDate="hasFutureStartDate"
            :isCourseEnded="isCourseEnded"
            @onEnroll="onEnroll"
          />
        </div>
        <div
          v-else
          id="cd-footer-section"
          class="cd-fixed-footer-section d-block d-lg-none"
        >
          <div class="footer-container">
            <LxpButton
              variant="primary"
              :class="['btn btn-md', !isAuthorized ? 'gradient-bg' : '']"
              :pill="true"
              :block="true"
              @click="startLearning"
            >
              <img
                src="@/assets/images/courseware/enroll-white.svg"
                alt="enroll"
                :class="{
                  'mr-2': language !== 'ar',
                  'ml-2': language === 'ar'
                }"
                width="auto"
                height="auto"
              />{{ $t("course.enroll_now") }}
            </LxpButton>
            <div class="cd__register-text">
              <a class="cd__register--link" href="javascript:;" @click="signUp">
                {{ $t("general.register") }}
              </a>
              <span class="cd__start-learning">{{
                $t("general.to_start_learning")
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <unenroll-modal :courseId="this.getSelectedCourse.id"></unenroll-modal>
      <!-- Modals -->
      <CourseDetailModals></CourseDetailModals>
    </div>
  </section>
</template>
<script>
import utils from "@/router/utils";
import { mapGetters, mapState } from "vuex";
import { LxpBreadcrumb, LxpButton, CourseOverview } from "didactica";
import moment from "moment";
import COMMAN_MODAL_IDS from "@/components/Shared/CommonModalConstants";
import RecommendedCourses from "@/components/Dashboard/RecommendedCourses.vue";
// wrapper components
import Shimmer from "@/components/WrapperComponents/Shimmer.vue";
import UnenrollModal from "@/components/CourseDetail/Modals/UnenrollModal.vue";
// child components
import Actions from "@/components/CourseDetail/Actions.vue";
import ModuleList from "@/components/CourseDetail/ModulesTabContent.vue";
import CredentialsTabContent from "@/components/CourseDetail/CredentialsTabContent.vue";
import PageNotFound from "./PageNotFound.vue";
import CourseDetailModals from "@/components/CourseDetail/Modals/CourseDetailModals.vue";
//mixins
import learningSpaceMixin from "@/components/CourseDetail/mixins/learningSpace";
import courseDetailMixin from "@/components/CourseDetail/mixins/courseDetail";
import redirectToLearningSpace from "@/core/mixins/redirectToLearningSpace";
import ctaActionsMixin from "@/components/CourseDetail/mixins/ctaActions";
export default {
  components: {
    Shimmer,
    Actions,
    RecommendedCourses,
    ModuleList,
    CredentialsTabContent,
    UnenrollModal,
    CourseDetailModals,
    LxpBreadcrumb,
    LxpButton,
    CourseOverview,
    PageNotFound
  },
  mixins: [
    courseDetailMixin,
    redirectToLearningSpace,
    ctaActionsMixin,
    learningSpaceMixin
  ],
  props: {
    isAuthorized: Boolean
  },
  data() {
    return {
      breadcrumbs: [
        {
          icon: require("@/assets/images/courseware/learning-interest.svg"),
          text: this.$t("course.breadcrumb.course"),
          to: { name: "course-discovery" }
        },
        {
          icon: null,
          text: "",
          active: true
        }
      ],
      isLoading: true,
      max: 100,
      scTimer: 0,
      showingFullText: false,
      legacyContent: false,
      isOpen: false,
      isVideoView: false,
      isPlayIconVisible: true,
      isMobile: false
    };
  },
  computed: {
    ...mapGetters([
      "getAuthProfile",
      "allConfig",
      "getCourseOverview",
      "getPageNotFound"
    ]),
    ...mapState(["course"]),

    overviewContent() {
      return this.getCourseOverview;
    },
    selectedCourseOrg() {
      return this.$store.state.selectedCourse.org;
    },
    formattedBody() {
      if (this.showingFullText) {
        return this.getSelectedCourse.short_description;
      } else {
        return this.getSelectedCourse.short_description
          ? `${this.getSelectedCourse.short_description
              .slice(0, 100)
              .trim()}...`
          : null;
      }
    },

    cStep() {
      if (this.isAuthorized) {
        let step = 1;
        if (!this.getSelectedCourse.is_enrolled) {
          step = 1;
        }
        if (this.getSelectedCourse.is_enrolled && !this.isCourseStarted) {
          step = 2;
        }
        if (this.getSelectedCourse.is_enrolled && this.isCourseStarted) {
          step = 3;
        }
        if (this.getSelectedCourse.course_completed) {
          step = 4;
        }
        if (
          this.getSelectedCourse.is_enrolled &&
          !this.getSelectedCourse.course_completed &&
          this.isCourseEnded
        ) {
          step = 5;
        }

        return step;
      } else {
        return null;
      }
    },

    startingSoonTooltipData() {
      if (this.isAuthorized) {
        let date = this.getSelectedCourse.course_starts_at
          ? moment(this.getSelectedCourse.course_starts_at).format("DD/MM/YY")
          : "";
        return `${this.$t("course.scheduled_to_start") + " " + date + "."}`;
      } else {
        return null;
      }
    },

    reviewTooltipData() {
      if (this.isAuthorized) {
        return `${this.$t("course.review_closed_alert.message")}`;
      } else {
        return null;
      }
    },

    setBreadcrumbsPath() {
      var courseTitle = "";

      if (
        this.getSelectedCourse !== undefined &&
        this.getSelectedCourse.title !== undefined
      ) {
        let label = this.getSelectedCourse.title;
        if (label.length <= 30) {
          courseTitle = label;
        } else {
          courseTitle = label.slice(0, 30) + " ...";
        }
      }
      if (!this.isAuthorized) {
        return [
          {
            icon: null,
            text: this.$t("general.menu.home"),
            to: { name: "Home" }
          },
          {
            icon: null,
            text: this.$t("general.menu.discovery"),
            to: { name: "discovery" }
          },
          {
            icon: null,
            text: courseTitle,
            active: true
          }
        ];
      } else if (this.$route.query.from === "learning-programmes") {
        return [
          {
            icon: require("@/assets/images/courseware/school-grey.svg"),
            text: this.$i18n.t("general.menu.my_learning")
          },
          {
            icon: null,
            text: this.$i18n.t("general.menu.my_programmes"),
            to: { name: "my-programmes" }
          },
          {
            icon: null,
            text: courseTitle,
            active: true
          }
        ];
      } else if (this.$route.query.from === "home-courses") {
        return [
          {
            icon: require("@/assets/images/courseware/dashboard.svg"),
            text: this.$t("general.menu.home"),
            to: { name: "Dashboard" }
          },
          {
            icon: null,
            text: courseTitle,
            active: true
          }
        ];
      } else if (this.$route.query.from === "discovery") {
        return [
          {
            icon: require("@/assets/images/menu/library-discovery.svg"),
            text: this.$t("general.menu.discovery"),
            to: { name: "course-discovery" }
          },
          {
            icon: null,
            text: courseTitle,
            active: true
          }
        ];
      } else if (this.$route.query.from === "learning-space") {
        const courseOrg = this.$route.query.org.toUpperCase();
        return [
          {
            icon: require("@/assets/images/menu/library-discovery.svg"),
            text: this.$t("general.menu.discovery"),
            to: { name: "course-discovery" }
          },
          {
            text: courseOrg,
            to: { name: "LearningSpace", params: { org: courseOrg } },
            active: false
          },
          {
            icon: null,
            text: courseTitle,
            active: true
          }
        ];
      }
      return [
        {
          icon: require("@/assets/images/courseware/learning-interest.svg"),
          text: this.$t("general.menu.discovery"),
          to: { name: "course-discovery" }
        },
        {
          icon: null,
          text: courseTitle,
          active: true
        }
      ];
    },
    authToken() {
      if (this.isAuthorized) {
        return `Bearer ${this.$keycloak.token}`;
      } else {
        return null;
      }
    },
    currentOrg() {
      if (this.isAuthorized) {
        return this.$route.params.org;
      } else {
        return null;
      }
    }
  },
  created() {
    if (this.isAuthorized) {
      // Reset the session cookies
      this.$store.commit("SET_SESSION_COOKIE", []);
    } else {
      if (document.getElementsByTagName("BODY")[0].classList.length === 0) {
        document.getElementsByTagName("BODY")[0].style.overflow = "auto";
      }
      window.scrollTo(0, 0);
    }
  },

  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
    if (this.isAuthorized) {
      // init after importing the layout
      if (this.isUserFluidLayoutLoaded()) {
        this.$nextTick(async () => {
          this.getCourseDetail();
          this.getRandomCourse(3);
        });
      }
    } else {
      this.getACourse();
      this.getRandomCourse(3);
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    getACourse() {
      this.$store
        .dispatch("getAPublicCourse", {
          course_id: this.$route.params.id
        })
        .then(() => {
          this.isLoading = false;
          this.getCourseOverviewData();
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    handleError() {
      this.placeholderImage =
        "https://files.lxp.academy.who.int/learning-experience-platform/illustration/course-detail-illustration.svg";
    },
    signUp() {
      this.$keycloak.login({
        redirectUri: this.redirectUri,
        action: "register",
        locale: this.language
      });
    },
    async getRandomCourse(count) {
      this.recommendedCourses = await this.$store.dispatch(
        "getRandomCourses",
        count
      );
    },
    playVideo() {
      this.isVideoView = true;
    },
    startLearning() {
      this.$keycloak.login({
        redirectUri: utils.getRedirectUri(),
        locale: this.language
      });
    },
    onToggle() {
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        this.$bvModal.show("course-details-modal");
        document.body.style.overflow = "hidden";
        document.getElementById("cd-footer-section").style.zIndex = "-1";
      } else {
        document.body.style.overflow = "auto";
        document.getElementById("cd-footer-section").style.zIndex = "9999";
      }
    },
    getCourseDetail() {
      this.$store
        .dispatch("getACourse", {
          course_id: this.$route.params.id,
          version: "v1"
        })
        .then(() => {
          this.isLoading = false;
          this.getCourseOverviewData();
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    getCourseOverviewData() {
      this.$store
        .dispatch("getCourseOverview", {
          course_id: this.$route.params.id
        })
        .then(() => {
          this.getLearningSpaceSummary(this.selectedCourseOrg);
        })
        .catch(() => {
          this.getLearningSpaceSummary(this.getSelectedCourse.org);
          this.legacyContent = true;
          this.hasError = true;
        });
    },

    onEnroll(step) {
      if (!this.isAuthorized) {
        this.signUp();
      } else {
        this.cLearningStep = step;
        if (this.getAuthProfile.is_mfa_enabled) {
          if (step === 3) {
            // reset the current menu to modules, just in case if the learner
            // left the self-paced course from teams page
            this.$store.commit("SET_CURRENT_MENU_ITEM", "modules");
            this.$router
              .push({
                path: `/self-paced/${this.$route.params.id}`,
                query:
                  this.$route.query.from === "learning-programmes"
                    ? { from: "learning-programmes" }
                    : this.$route.query.from === "home-courses"
                    ? { from: "home-courses" }
                    : {}
              })
              .catch(() => {});
          } else if (step === 2) {
            this.$store
              .dispatch("enrollCourse", this.$route.params.id)
              .then(() => {
                this.$store.dispatch("getACourse", {
                  course_id: this.$route.params.id,
                  version: "v1"
                });
              })
              .catch(() => {});
          }
        } else {
          this.$bvModal.show(COMMAN_MODAL_IDS.MFA_CONFIGURE_MODAL);
        }
      }
    },
    enrollSuccessModalClose() {
      this.$router.push({
        name: "Self Paced",
        params: { id: this.$route.params.id }
      });
    },
    navigateToLink(item) {
      this.$router.push(item.to);
    },
    hidePlayIcon(value) {
      this.isPlayIconVisible = value;
    },
    onResize() {
      this.isMobile = window.innerWidth < 768;
    },
    showCourseDetailModal() {
      if (this.isMobile) {
        this.$bvModal.show("course-details-modal");
      }
    }
  }
};
</script>
<style lang="scss">
@import "../scss/course-details/typography";
@import "../scss/course-details/modals";
@import "../scss/course-details/_common.scss";
</style>
<style lang="scss" scoped>
@media only screen and (min-width: 1200px) {
  .cd-video-section {
    .left-panel {
      width: 827px;
      max-width: 827px;
    }
    .right-panel {
      padding-left: 28px;
    }
  }
}
</style>
