import axios from "axios";
export default {
  data() {
    return {
      key: "",
      showSummary: true,
      spaceInfo: {
        logo: "",
        name: "",
        short_headline: "",
        terms_and_conditions: ""
      }
    };
  },
  computed: {
    authenticated() {
      return this.$keycloak.authenticated;
    }
  },
  methods: {
    getLearningSpaceSummary(orgId) {
      axios
        .get(`${this.allConfig.baseURL}/spaces/${orgId}/summary/`)
        .then(res => {
          this.spaceInfo = res.data;
          this.key++;
          this.isLoading = false;
        })
        .catch(() => {
          this.showSummary = false;
          this.isLoading = false;
        });
    },
    onSpaceClick(space) {
      if (this.showSummary) {
        if (this.authenticated) {
          this.navigateToLearningSpace(space.org_short_name);
        } else {
          this.navigateToPartnerSpace(space.org_short_name);
        }
      }
    }
  }
};
